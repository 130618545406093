<template>
  <div class="website">
    <div class="title">
      <img src="../../../assets/img/wang/tip.png" alt="" />
      <span>工商信息</span>
    </div>
    <el-row :gutter="20">
      <el-form
        :model="businessForm"
        :rules="rules"
        @submit.native.prevent
        ref="businessForm"
        label-width="300px"
      >
        <el-col :span="11">
          <el-form-item label="经营状态  ">
            <el-select
              class="select"
              v-model="businessForm.operatingStatus"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in managementStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="企业名称 " >
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="businessForm.companyName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="法人代表人  ">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="businessForm.legalPerson"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="注册资金  ">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="businessForm.registeredFunds" 
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="成立时间  ">
            <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
              class="time-select"
              clearable
              v-model="businessForm.createDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="所属城市  ">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.cityName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="电话">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.phone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="更多电话  ">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.morePhone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="邮箱  ">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.email"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="统一社会信用代码">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.socialCreditCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="纳税人识别号 ">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.taxIdentyNumber"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="11">
          <el-form-item label="注册号 -">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.registeredNumber"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="11">
          <el-form-item label="组织机构代码  ">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.organizationCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="公司类型  ">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.companyType"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="所属行业  ">
            <el-input
              placeholder="请输入内容"
              clearable
              class="input"
              v-model="businessForm.belongIndustry"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="网址  ">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="businessForm.url"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="企业地址 ">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="businessForm.companyAddress"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="经营范围 ">
            <el-input
              placeholder="请输入内容"
              class="input"
              type="textarea"
              :rows="4"
              v-model="businessForm.businessScope"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
  
  <script>
export default {
  props: {
    rules: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      businessForm: {
        //工商信息表单
        operatingStatus: null, //经营状态
        companyName: null, //企业名称
        legalPerson: null, //法人代表人
        registeredFunds: null, //注册资金
        createDate: null, //成立时间
        cityName: null, //所属城市
        phone: null, //电话
        morePhone: null, //更多电话
        email: null, //emil
        taxIdentyNumber: null, //纳税人识别号
        registeredNumber: null, //注册号
        organizationCode: null, //组织机构代码
        companyType: null, //公司类型
        belongIndustry: null, //所属行业
        url: null, //网址
        companyAddress: null, //企业地址
        businessScope: null, //经营范围
        socialCreditCode: null, // 统一社会信用代码
      },
      managementStatusOptions: [
        { label: "正常经营", value: "0" },
        { label: "暂停营业", value: "1" },
      ], //经营状态
    };
  },
  methods: {
     // 回显数据
     setData(query) {
      console.log()
      const Query = JSON.parse( query)
      Object.keys(this.businessForm).forEach(key=> {
        this.businessForm[key] =   Query[key]
      })
    },
    getData() {
      console.log(this,'gongshang');
        const {businessForm} = this
        let aaa = false;
        Object.keys(businessForm).forEach(v => {
            if(businessForm[v]) {
                if(!businessForm.socialCreditCode  || businessForm.socialCreditCode.length == 0) {
                    this.$message.warning("请在工商信息里填写统一社会信用代码！")
                    aaa = true
                    return
                    }
                return
            }
        });
        businessForm.aaa = aaa
      return this.businessForm;
    },
    deleteData() {
      this.$options.data().businessForm;
    },
  },
};
</script>
  
  <style lang="scss" scoped >
.website {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 574px;
  height: auto;
  padding-bottom: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  .title {
    display: flex;
    height: 22px;
    margin: 25px 0px 31px 19px;
    img {
      height: 22px;
      width: 22px;
      margin-right: 3px;
    }
    span {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }
  .input {
    //输入框
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      height: 36px;
      width: 660px;
    }
  }
  .time-select {
    width: 660px;
    height: 36px;
    margin: 0;
    ::v-deep .el-input__inner {
      height: 36px;
    }
  }
  //表单
  ::v-deep .el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .el-form-item__label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 33px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .el-form-item__content {
      margin-left: 33px !important;
    }
  }
  ::v-deep .el-col.el-col-24 {
    height: 148px;
  }
  .select {
    //下拉选择
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      width: 660px;
      height: 36px;
    }
  }
}
</style>