<template>
  <div class="website">
    <div class="title">
      <img src="../../../assets/img/wang/tip.png" alt="" />
      <span>公众号信息</span>
    </div>
    <el-row :gutter="20">
      <el-form
        :model="weixinForm"
        :rules="rules"
        @submit.native.prevent
        ref="websiteForm"
        label-width="200px"
      >
        <el-col :span="11">
          <el-form-item label="用户名称" prop="accountName">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.accountName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="主页地址" prop="homeUrl">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.homeUrl" 
            ></el-input>
          </el-form-item>
        </el-col>
           <el-col :span="11">
          <el-form-item label="注册时间">
            <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              class="time-select"
              v-model="weixinForm.createTime"
              clearable
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        
        <el-col :span="11">
          <el-form-item label="所属地区">
            <el-cascader :placeholder="ownRegionplaceholder"  :props="ownRegionProps" v-model="ownRegion"  class="select diquClass" :options="ownRegionList" clearable>
            </el-cascader>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="11">
          <el-form-item label="所属省份" prop="ipAddress">
            <el-select
              class="select"
              v-model="weixinForm.provinceId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in provanceOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="所属城市" prop="computerRoom">
            <el-select
              class="select"
              v-model="weixinForm.cityId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in cityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="所属区县">
            <el-select
              class="select"
              v-model="weixinForm.districtId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in districtOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->

        <el-col :span="11">
          <el-form-item label="微信账号">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.weixinAccount"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="公众号名称" >
            
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.accountName"
            ></el-input>
      
          </el-form-item>
        </el-col>
        <!-- <el-col :span="11">
          <el-form-item label="发文数量">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.contentCount"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="11">
          <el-form-item label="账号主体类型">
            <el-select
              class="select"
              v-model="weixinForm.accountBodyType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in body_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="11"> 
          <el-form-item label="账号主体名称">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.accountBodyName"
            ></el-input>
          </el-form-item>
        </el-col> -->

        <el-col :span="11">
          <el-form-item label="处置状态">
            <el-select
              class="select"
              v-model="weixinForm.disposeState"
              placeholder="请选择"
            >
              <el-option
                v-for="item in disposeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="认证时间" >
            <el-date-picker
              class="time-select"
              v-model="weixinForm.authenticateTime"
              type="date"
              clearable
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="账号状态" >
            <el-select
              class="select"
              v-model="weixinForm.messageTypes"
              placeholder="请选择"
            >
              <el-option
                v-for="item in message_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="手机号">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="weixinForm.customerServicePhone"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
  
  <script>
  import { cityList, districtList } from "../../../api/login/index";
export default {
  data() {
    return {
      rules: {
        accountName: [
          { required: true, message: "请输入网站名称", trigger: "blur" },
        ], //网站名称
        domainName: [
          { required: true, message: "请输入网站域名", trigger: "blur" },
        ], //网站域名
      },
      //网站信息表单
      weixinForm: {
        accountId: null, //账号名称ID
        homeUrl:null,// 主页地址
        provinceId: null, //所属省份
        cityId: null, //所属城市
        districtId: null, //所属区县
        weixinAccount:null,    // 微信账号
        accountName: null, //公众号名称
        contentCount: null, //发文数量
        accountBodyType:null, // 账号主体类型
        // accountBodyName:null, // 账号主体名称
        createTime:null, // 注册时间
        disposeState:null,// 处置状态
        authenticateTime: null, //认证时间
        messageTypes :null,// 账号状态
        customerServicePhone: null, //手机号
      },
      // 认证情况
      authenticationInfoOptions: [
        { value: 1, label: "普通账号" },
        { value: 2, label: "微博达人" },
        { value: 3, label: "个人认证" },
        { value: 4, label: "机构认证" },
      ],
      ownRegion: [], // 地区
      ownRegionplaceholder:'',
      ownRegionProps: {
        lazy: true,
        multiple: false,
        checkStrictly: true,
        lazyLoad: this.ownRegionLoadNode,
      }, // 地区选择
      ownRegionList:JSON.parse( window.localStorage.getItem('ownRegionList'))  ||  [], // 所在地区配置
      disposeOption:JSON.parse( window.localStorage.getItem('wechat_dispose_state'))  || [], // 处置状态
      platform_type:JSON.parse(window.localStorage.getItem("platform_type")) ||[], // 账号平台类型
      message_type:JSON.parse(window.localStorage.getItem("message_type")) || [], // 账号状态
      // authentication_type:[],//认证情况
      body_type: JSON.parse(window.localStorage.getItem("body_type")) || []// 主体类型
    };
  },
  async mounted() {
    await this.$main.mySetTimeOut(1000)
    this.ownRegionList=JSON.parse( window.localStorage.getItem('ownRegionList'))  ||  []// 所在地区配置
  },
  methods:{
    //机构所属区域
    async ownRegionLoadNode(node, resolve) {
      let data = {
        parentCode: node.data ? node.data.code : null,
      };
      let dat = [];
      if (node.level == 1) {
        let res = await cityList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
          };
        });
      }
      if (node.level == 2) {
        let res = await districtList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
            // lazy: -1,
            leaf:true
          };
        });
      }
      resolve(dat);
    },
    // 获取字典
    getZidian(obj) { 
        Object.keys(obj).forEach(Key =>this[Key] = obj[Key])
    },
     // 回显数据
     setData(query) {
      Object.keys(this.weixinForm).forEach(key=> {
        this.weixinForm[key] =   query[key]
      })
      this.ownRegionplaceholder = query.areaName;
    },
    getData() {
      if(this.ownRegion){
        if(this.ownRegion[0]) this.weixinForm.provinceId = this.ownRegion[0]
        if(this.ownRegion[1]) this.weixinForm.cityId =this.ownRegion[1]
        if(this.ownRegion[2]) this.weixinForm.districtId =this.ownRegion[2]
      }
      let ifaaa;
      this.$refs["websiteForm"].validate((valid) => {
        ifaaa = valid;
      }); 
      if (ifaaa)  return this.weixinForm
      if (!ifaaa) return false;
    },
  }
};
</script>
  
  <style lang="scss" scoped >
  .diquClass {
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #333333;
  }
}
.website {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 574px;
  height: auto;
  padding-bottom: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  .title {
    display: flex;
    height: 22px;
    margin: 25px 0px 31px 19px;
    img {
      height: 22px;
      width: 22px;
      margin-right: 3px;
    }
    span {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }
  .input {
    //输入框
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      height: 36px;
      width: 660px;
    }
  }
  .time-select {
    width: 660px;
    height: 36px;
    margin: 0;
    ::v-deep .el-input__inner {
      height: 36px;
    }
  }
  //表单
  ::v-deep .el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .el-form-item__label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 33px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .el-form-item__content {
      margin-left: 33px !important;
    }
  }
  ::v-deep .el-col.el-col-24 {
    height: 148px;
  }
  .select {
    //下拉选择
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      width: 660px;
      height: 36px;
    }
  }
}
</style>