<template>
  <div class="website">
    <div class="title">
      <img src="../../../assets/img/wang/tip.png" alt="" />
      <span>备案信息</span>
    </div>
    <el-row :gutter="20">
      <el-form
        :model="filingsForm"
        :rules="rules"
        @submit.native.prevent
        ref="filingsForm"
        label-width="300px"
      >
        <el-col :span="11">
          <el-form-item label="网站名称">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="filingsForm.accountName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="网站icp备案号">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="filingsForm.orgIcpNumber"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="网站域名">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="filingsForm.domainName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="主办单位名称">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="filingsForm.companyName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="网站首页地址">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="filingsForm.homeUrl"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="主办单位性质">
            <el-select
              class="select"
              v-model="filingsForm.organizerTypeId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in NatureOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="负责人电话">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="filingsForm.sitePersonphone"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="主办单位地址">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="filingsForm.companyAddress"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="所在地区">
            <el-cascader
              :props="ownRegionProps"
              v-model="ownRegion"
              class="select diquClass"
              :options="ownRegionList"
              clearable
              :placeholder="ownRegionplaceholder"
            >
            </el-cascader>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="11">
          <el-form-item label="是否限制接入">
            <el-select
              class="select"
              v-model="filingsForm.isLimitAccess"
              placeholder="请选择"
            >
              <el-option
                v-for="item in limitOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :span="11"> 
          <el-form-item label="详细信息 -- ">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="filingsForm.detailInfo"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="11">
          <el-form-item label="审核时间">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              class="select"
              v-model="filingsForm.passTime"
              type="datetime"
              clearable
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="11">
          <el-form-item label="前置审批">
            <el-select
              class="select"
              v-model="filingsForm.preApprovalId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in preApprovalOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
      </el-form>
    </el-row>
  </div>
</template>

<script>
import { cityList, districtList } from "../../../api/login/index";
export default {
  props: {
    rules: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filingsForm: {
        //备案信息表单
        orgIcpNumber: null, //网站icp备案号
        companyName: null, //主办单位名称
        organizerTypeId: null, //主办单位性质
        companyAddress: null, //主办单位地址
        provinceId: null, //所属省份
        cityId: null, //所属城市
        districtId: null, //所属区县
        accountName: null, //网站名称
        homeUrl: null, //网站首页地址
        domainName: null, //网站域名
        sitePersonphone: null, //负责人电话
        // isLimitAccess: null, //是否限制接入
        detailInfo: null, //详细信息
        passTime: null, //审核时间
        // preApprovalId: null, //前置审批
        creditCode: null, //统一社会信用代码
      },
      ownRegion: [], // 地区
      ownRegionplaceholder: "",
      ownRegionProps: {
        lazy: true,
        multiple: false,
        checkStrictly: true,
        lazyLoad: this.ownRegionLoadNode,
      }, // 地区选择
      ownRegionList:
        JSON.parse(window.localStorage.getItem("ownRegionList")) || [], // 所在地区配置
      limitOptions: [
        { label: "是", value: true },
        { label: "否", value: false },
      ], //是否限制接入
      preApprovalOptions:
        JSON.parse(window.localStorage.getItem("pre_approval")) || [], // 前置审批
      NatureOptions:
        JSON.parse(window.localStorage.getItem("organizer_type")) || [], // 主办单位性质
    };
  },
  methods: {
    // 回显数据
    setData(query) {
      console.log(query,'查');
      Object.keys(this.filingsForm).forEach((key) => {
        this.filingsForm[key] = query[key];
      });
      this.ownRegionplaceholder = query.areaName;//所属地区名称回显
    },
    // 获取数据
    getData() {
      if (this.ownRegion) {
        if (this.ownRegion[0]) {
          this.filingsForm.provinceId = this.ownRegion[0]
        }else{//如果不清空会把上一次的省市区id传过去
          this.filingsForm.provinceId = null
        }
        if (this.ownRegion[1]) {
          this.filingsForm.cityId = this.ownRegion[1];
        }else{
          this.filingsForm.cityId = null
        }
        if (this.ownRegion[2]) {
          this.filingsForm.districtId = this.ownRegion[2];
        }else{
          this.filingsForm.districtId= null
        }
      }
      //7月4
      delete this.filingsForm.accountName
      delete this.filingsForm.domainName
      return this.filingsForm;
    },
    //机构所属区域
    async ownRegionLoadNode(node, resolve) {
      let data = {
        parentCode: node.data ? node.data.code : null,
      };
      let dat = [];
      if (node.level == 1) {
        let res = await cityList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
          };
        });
      }
      if (node.level == 2) {
        let res = await districtList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
            lazy: -1,
          };
        });
      }
      resolve(dat);
    },
  },
  async mounted() {
    await this.$main.mySetTimeOut(1000);
    this.ownRegionList =
      JSON.parse(window.localStorage.getItem("ownRegionList")) || []; // 所在地区配置
  },
};
</script>

<style lang="scss" scoped >
.diquClass {
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #333333;
  }
}

.website {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 574px;
  height: auto;
  padding-bottom: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  .title {
    display: flex;
    height: 22px;
    margin: 25px 0px 31px 19px;
    img {
      height: 22px;
      width: 22px;
      margin-right: 3px;
    }
    span {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }
  .input {
    //输入框
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      height: 36px;
      width: 660px;
    }
  }
  .time-select {
    width: 660px;
    height: 36px;
    margin: 0;
    ::v-deep .el-input__inner {
      height: 36px;
    }
  }
  //表单
  ::v-deep .el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .el-form-item__label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 33px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .el-form-item__content {
      margin-left: 33px !important;
    }
  }
  ::v-deep .el-col.el-col-24 {
    height: 148px;
  }
  .select {
    //下拉选择
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      width: 660px;
      height: 36px;
    }
  }
}
</style>