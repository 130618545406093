<template>
  <div class="website">
    <div class="title">
      <img src="../../../assets/img/wang/tip.png" alt="" />
      <span>网站信息</span>
    </div>
    <el-row :gutter="20">
      <el-form
        :model="websiteForm"
        :rules="rules"
        @submit.native.prevent
        ref="websiteForm"
        label-width="300px"
      >
        <el-col :span="11">
          <el-form-item label="网站名称" prop="accountName">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model.trim="websiteForm.accountName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="网站地址" prop="domainName">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model.trim="websiteForm.domainName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="服务器IP地址">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="websiteForm.serverIp"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="服务器机房信息">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="websiteForm.serverRoom"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="境内外接入">
            <el-select
              class="select"
              v-model="websiteForm.insideOrOutside"
              placeholder="请选择"
            >
              <el-option
                v-for="item in accessOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="接入国家">
            <el-select
              class="select"
              v-model="websiteForm.accessCountry"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in countryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="网站编码类型">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="websiteForm.siteEncoding"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="网站状态">
            <el-select
              class="select"
              v-model="websiteForm.messageTypes"
              multiple
              collapse-tags
              placeholder="请选择"
              
            >
              <el-option
                v-for="item in websiteStatusOptions"
                :key="item.value" 
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="11">
          <el-form-item label="备注信息">
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="websiteForm.remark"
            ></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="11">
          <el-form-item label="网站分类">
            <el-select
              class="select"
              v-model="websiteForm.bodyType"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in websiteTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="最后更新时间">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              class="select"
              v-model="websiteForm.lastAutoUpdateStateTime"
            
              clearable
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="接入省份"> 
            <!-- <el-select
              class="select"
              v-model="websiteForm.provinceId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in ownRegionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <el-input
              placeholder="请输入内容"
              class="input"
              clearable
              v-model="websiteForm.accessProvince"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="所在地区">
          
            <el-cascader :props="ownRegionProps" v-model="ownRegion"  class="select" :options="ownRegionList" clearable>
            </el-cascader>
          </el-form-item> -->
        </el-col>
        <el-col :span="11">
          <el-form-item label="语言种类">
            <el-select
              class="select"
              v-model="websiteForm.siteLanguage"
              placeholder="请选择"
            >
              <el-option
                v-for="item in siteLanguageOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import { cityList, districtList } from "@/api/login/index";
export default {
  data() {
    return {
      isEdit:'',
      rules: {
        accountName: [
          { required: true, message: "请输入网站名称", trigger: "blur" },
        ], //网站名称
        domainName: [
          { required: true, message: "请输入网站域名", trigger: "blur" },
        ], //网站域名
      },
      websiteForm: {
        //网站信息表单
        accountName: null, //网站名称
        domainName: null, //网站域名
        serverIp: null, //ip地址
        serverRoom: null, //服务器机房信息
        insideOrOutside: null, //境内外接入
        accessCountry: null, //接入国家
        siteEncoding: null, //网站编码类型
        messageTypes: null, // 当前网站状态
        // remark: null, //备注信息
        bodyType: null, //网站分类
        siteLanguage: null, // 语言种类
        lastAutoUpdateStateTime: null, // 最后更新时间
        accessProvince: null, // 省份
        // provinceId:null // 省份
      },
      ownRegion: [], // 地区
      ownRegionProps: {
        lazy: true,
        multiple: false,
        checkStrictly: true,
        lazyLoad: this.ownRegionLoadNode,
      }, // 地区选择
      ownRegionList:
        JSON.parse(window.localStorage.getItem("ownRegionList"))|| [], //网站的就写splice(1,) 对着呢 别改 所在地区配置
      accessOptions:
        JSON.parse(window.localStorage.getItem("access_way")) || [], //境内外接入
      countryOptions:
        JSON.parse(window.localStorage.getItem("access_country")) || [], //接入国家
      websiteStatusOptions:
        JSON.parse(window.localStorage.getItem("message_type")) || [], //网站状态
      websiteTypeOptions:
        JSON.parse(window.localStorage.getItem("body_type")) || [], //网站分类
      siteLanguageOptions:
        JSON.parse(window.localStorage.getItem("language_category")) || [], //语言种类
    };
  },
  async mounted() {
    await  this.$main.mySetTimeOut(500)
    this.ownRegionList = JSON.parse(window.localStorage.getItem("ownRegionList"))|| [] //网站的就写splice(1,) 对着呢 别改 所在地区配置
  },
  created(){
    this.isEdit = this.$route.query.isEdit
  },
  methods: {
    //机构所属区域
    async ownRegionLoadNode(node, resolve) {
      let data = {
        parentCode: node.data ? node.data.code : null,
      };
      let dat = [];
      if (node.level == 1) {
        let res = await cityList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
          };
        });
      }
      if (node.level == 2) {
        let res = await districtList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
            lazy: -1,
          };
        });
      }
      resolve(dat);
    },
    // 提交数据
    getData() {
      let ifaaa;
      this.$refs["websiteForm"].validate((valid) => {
        ifaaa = valid;
      });
      if (this.websiteForm.messageTypes)
        this.websiteForm.messageTypes =  this.websiteForm.messageTypes.join(",");
      if (ifaaa) return this.websiteForm;
      if (!ifaaa) return false;
    },
    // 回显数据
    setData(query) {
      console.log(query)
      Object.keys(this.websiteForm).forEach(key=> {
        this.websiteForm[key] = query[key]
      })
      if(this.websiteForm.messageTypes) {
        this.websiteForm.messageTypes = this.websiteForm.messageTypes.split(',')
      }
    }
  },
};
</script>

<style lang="scss" scoped >
.website {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 574px;
  height: auto;
  padding-bottom: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
  .title {
    display: flex;
    height: 22px;
    margin: 25px 0px 31px 19px;
    img {
      height: 22px;
      width: 22px;
      margin-right: 3px;
    }
    span {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }
  .input {
    //输入框
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      height: 36px;
      width: 660px;
    }
  }
  .time-select {
    width: 660px;
    height: 36px;
    margin: 0;
    ::v-deep .el-input__inner {
      height: 36px;
    }
  }
  //表单
  ::v-deep .el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .el-form-item__label {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 33px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .el-form-item__content {
      margin-left: 33px !important;
    }
  }
  ::v-deep .el-col.el-col-24 {
    height: 148px;
  }
  .select {
    //下拉选择
    width: 660px;
    height: 36px;
    ::v-deep .el-input__inner {
      width: 660px;
      height: 36px;
    }
  }
}
</style>